<template>
  <div>
    <v-row>
      <v-col cols="12" sm="12" md="12">
        <v-dialog v-model="dialog" persistent width="1650px" scrollable>
          <template v-slot:activator="{ on, attrs }">
            <v-row>
              <v-col cols="2" sm="4" md="3">
                <v-btn color="primary" dark v-bind="attrs" v-on="on">
                  New project
                </v-btn>
              </v-col>

              <v-col cols="10" sm="8" md="9">
                <v-radio-group class="float-right" v-model="filterOptions" row>
                  <v-radio label="New" :value="0"></v-radio>

                  <v-radio label="In progress" :value="5"></v-radio>

                  <v-radio label="Completed" :value="10"></v-radio>

                  <v-radio label="Closed" :value="15"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="2" sm="2" md="2">
                <v-select
                  v-model="selectOptions.project"
                  :items="computedProjectCollection"
                  label="Select project"
                  item-text="name"
                  item-value="id"
                  return-object
                ></v-select>
              </v-col>

              <v-col cols="2" sm="2" md="2">
                <v-select
                  v-model="selectOptions.employee"
                  :items="computedEmployeeCollection"
                  label="Select employee"
                  item-text="name"
                  item-value="id"
                  return-object
                ></v-select>
              </v-col>
              <v-col cols="2" sm="2" md="2">
                <v-select
                  v-model="selectOptions.pm"
                  :items="computedPMCollection"
                  label="Select PM"
                  item-text="name"
                  item-value="id"
                  return-object
                ></v-select>
              </v-col>
              <v-spacer> </v-spacer>
              <v-col cols="2" sm="2" md="2">
                <v-checkbox
                  v-model="projectTimes"
                  label="Edit project hours"
                  dense
                ></v-checkbox>
              </v-col>
            </v-row>
          </template>
          <v-card>
            <v-card-title>
              <v-col cols="12" sm="12" md="12">
                <span class="headline">{{ formTitle }}</span>
                <v-btn
                  @click="handleCloseModalForm"
                  style="float: right; cursor: pointer"
                  icon
                  color="#26223c"
                >
                  <v-icon> mdi-close</v-icon>
                </v-btn>
              </v-col>
              <!-- v-if="statusLine" -->
              <v-col cols="12" sm="12" md="12">
                <v-tabs dark background-color="#232341" fixed-tabs>
                  <v-tabs-slider color="#232341"></v-tabs-slider>
                  <v-tab
                    v-for="(i, index) in statuses"
                    :key="index"
                    :href="'#tab-' + index"
                    :disabled="i != formModel.status"
                  >
                    <span style="color: #fff">{{
                      $t("ITINER.status_" + i)
                    }}</span>
                  </v-tab>
                </v-tabs>
              </v-col>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-form v-model="formValid" ref="form">
                  <v-row>
                    <v-col cols="8" sm="10" md="10">
                      <v-btn
                        v-if="
                          formModel.status == 0 &&
                          formModel.entities.length > 0 &&
                          formModel.id
                        "
                        class="button"
                        color="primary mr-2"
                        elevation="2"
                        target="_blank"
                        @click="handleInProgress"
                      >
                        In progress
                      </v-btn>
                      <v-btn
                        v-if="formModel.status == 5"
                        class="button"
                        color="primary mr-2"
                        elevation="2"
                        target="_blank"
                        @click="handleCompleted"
                      >
                        Complete all
                      </v-btn>
                      <v-btn
                        v-if="formModel.status == 10"
                        class="button"
                        color="primary mr-2"
                        elevation="2"
                        target="_blank"
                        @click="handleClose"
                      >
                        Close
                      </v-btn>
                    </v-col>
                    <v-spacer></v-spacer>
                    <!-- v-if="formModel.id" -->
                    <v-col cols="4" sm="2" md="2">
                      <v-btn
                        v-if="formModel.id && formModel.deletable"
                        depressed
                        color="error"
                        @click="handleDeleteProject"
                      >
                        Delete
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-divider class="mt-5 mb-5"></v-divider>
                  <v-row>
                    <v-tabs vertical v-model="defaultTab">
                      <v-tab style="justify-content: flex-start">
                        <v-icon left> mdi mdi-settings </v-icon>
                        Project data
                      </v-tab>
                      <v-tab style="justify-content: flex-start">
                        <v-icon left> mdi mdi-calendar-clock </v-icon>
                        Project hours
                      </v-tab>
                      <v-tab style="justify-content: flex-start">
                        <v-icon left> mdi mdi-format-list-bulleted </v-icon>
                        Entity list
                      </v-tab>
                      <v-tab style="justify-content: flex-start">
                        <v-icon left> mdi mdi-puzzle </v-icon>
                        Component list
                      </v-tab>

                      <v-tab-item>
                        <v-card flat>
                          <v-card-text>
                            <v-row>
                              <v-col
                                cols="6"
                                sm="6"
                                md="6"
                                class="mt-2"
                                v-if="dialog"
                              >
                                <!-- :disabled="formModel.status > 0" -->
                                <v-select
                                  v-model="formModel.user_id"
                                  :items="computedPMMemberCollection"
                                  label="Project manager"
                                  item-text="fullName"
                                  item-value="id"
                                  @change="handleChangeUserId"
                                  :id="dynamicID"
                                  :rules="requiredRules"
                                  :disabled="formModel.status == 15"
                                />
                              </v-col>
                              <v-col cols="6" sm="6" md="6" class="mt-2">
                                <v-select
                                  v-model="formModel.site_id"
                                  :items="siteCollection"
                                  label="Select Site"
                                  item-text="name"
                                  item-value="id"
                                  :id="dynamicID"
                                  :rules="requiredRules"
                                  :disabled="
                                    formModel.id || formModel.status > 0
                                  "
                                />
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="6" sm="6" md="6">
                                <label for="datepicker-full-width">
                                  Begin date
                                </label>
                                <b-form-datepicker
                                  v-model="formModel.begin_date"
                                  :min="new Date(Date.now())"
                                  calendar-width="100%"
                                  locale="en"
                                  class="mb-2"
                                  style="max-width: 260px"
                                  start-weekday="1"
                                  required
                                  @context="handleChangeBeginDate"
                                  :disabled="
                                    formModel.id || formModel.status > 0
                                  "
                                ></b-form-datepicker>
                                <small
                                  v-if="
                                    messages['begin_date'] &&
                                    messages['begin_date'] != today
                                  "
                                  style="color: red"
                                >
                                  {{ messages["begin_date"] }}
                                </small>
                              </v-col>
                              <v-col cols="6" sm="6" md="6">
                                <label for="datepicker-full-width">
                                  Deadline
                                </label>
                                <b-form-datepicker
                                  v-model="formModel.deadline"
                                  :min="formModel.begin_date"
                                  calendar-width="100%"
                                  locale="en"
                                  class="mb-2"
                                  start-weekday="1"
                                  style="max-width: 260px"
                                  required
                                  @context="handleChangeDeadline"
                                  :disabled="
                                    formModel.id || formModel.status > 0
                                  "
                                ></b-form-datepicker>
                                <small
                                  v-if="messages['deadline']"
                                  style="color: red"
                                >
                                  {{ messages["begin_date"] }}
                                </small>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="12" sm="12" md="12">
                                <v-textarea
                                  v-model="formModel.comment"
                                  rows="5"
                                  label="Comment"
                                ></v-textarea>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-tab-item>
                      <v-tab-item>
                        <v-card flat>
                          <v-card-text>
                            <Members
                              :projectTimesData="formModel"
                              :members="computedMembers"
                              :userCollection="computedMemberCollection"
                              :inputData="memberInputData"
                              @handleAddMember="handleAddMember"
                            ></Members>
                          </v-card-text>
                        </v-card>
                      </v-tab-item>
                      <v-tab-item>
                        <v-card flat>
                          <v-card-text>
                            <v-form
                              v-model="formInputDataValid"
                              ref="formInputData"
                              class="mt-5"
                            >
                              <v-row v-if="!formModel.id">
                                <v-col cols="2" sm="2" md="3">
                                  <v-combobox
                                    v-if="!formModel.id"
                                    :items="computedSalesOrderCollection"
                                    v-model="inputData.so"
                                    item-text="so_number"
                                    label="Select SO"
                                    return-object
                                    :error-messages="messages['so']"
                                    @input="handleSOChange"
                                  >
                                  </v-combobox>
                                </v-col>
                                <v-col cols="2" sm="2" md="3">
                                  <v-combobox
                                    :items="itemCollectionManufactured"
                                    v-model="inputData.item"
                                    item-text="ReferenceName"
                                    label="Search item"
                                    return-object
                                    :rules="requiredRules"
                                    :error-messages="messages['item']"
                                    @input="handleInputItemChange"
                                  >
                                  </v-combobox>
                                </v-col>
                                <v-col cols="2" sm="1" md="1">
                                  <v-select
                                    v-model="inputData.quantity"
                                    label="Quantity"
                                    :items="inputData.quantityCollection"
                                    :rules="requiredRules"
                                    :id="dynamicID"
                                    @change="handleQuantityChange"
                                  />
                                </v-col>
                                <v-col cols="2" sm="1" md="1">
                                  <!-- :value="
                                      getQuantityUnitNameByid(
                                        componentInputData.item.quantity_unit_id
                                      )
                                    " -->
                                  <v-text-field
                                    v-model="inputData.quantityUnit"
                                    label="Quantity unit"
                                    disabled
                                    :id="dynamicID"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="2" sm="2" md="2">
                                  <v-text-field
                                    label="No. of pcs"
                                    type="number"
                                    min="0"
                                    v-model="inputData.num_of_pcs"
                                    :id="dynamicID"
                                    :rules="requiredRules"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="1" sm="1" md="1">
                                  <v-btn
                                    color="primary"
                                    dark
                                    class="mt-2"
                                    @click="handleAddItemToList"
                                  >
                                    Add to list
                                  </v-btn>
                                </v-col>
                              </v-row>

                              <v-row>
                                <v-col
                                  cols="12"
                                  sm="12"
                                  md="12"
                                  min-width="320"
                                >
                                  <v-data-table
                                    :headers="headersEntities"
                                    :items="computedEntitiesCollection"
                                    :items-per-page="-1"
                                    :hide-default-footer="true"
                                    disable-pagination
                                    class="elevation-1"
                                  >
                                    <template v-slot:item.actions="{ item }">
                                      <div style="width: 70px">
                                        <v-icon
                                          v-if="!formModel.id"
                                          small
                                          class="mr-2"
                                          @click="deleteItem(item)"
                                        >
                                          mdi-delete
                                        </v-icon>
                                        <v-btn
                                          v-if="
                                            formModel.status == 5 &&
                                            item.transactionId &&
                                            item.status == 'Under production'
                                          "
                                          small
                                          class="button"
                                          color="primary mr-2"
                                          elevation="2"
                                          target="_blank"
                                          @click="handleItemToCompleted(item)"
                                        >
                                          Completed
                                        </v-btn>
                                      </div>
                                    </template>
                                  </v-data-table>
                                </v-col>
                              </v-row>
                            </v-form>
                          </v-card-text>
                        </v-card>
                      </v-tab-item>
                      <v-tab-item>
                        <v-card flat>
                          <v-card-text>
                            <v-row>
                              <v-col cols="12" sm="12" md="12" min-width="320">
                                <v-data-table
                                  :headers="headersComponents"
                                  :items="computedComponentsCollection"
                                  :items-per-page="-1"
                                  :hide-default-footer="true"
                                  disable-pagination
                                  class="elevation-1"
                                >
                                </v-data-table>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-tab-item>
                    </v-tabs>
                  </v-row>
                </v-form>
                <v-divider></v-divider>
                <v-form
                  v-model="formInputDataValid"
                  ref="formInputData"
                  class="mt-5"
                >
                  <v-row> </v-row>
                </v-form>
                <v-row justify="center">
                  <v-dialog
                    v-model="dialogSelectTransactions"
                    persistent
                    scrollable
                    width="960px"
                  >
                    <v-card>
                      <v-card-title>Select items</v-card-title>
                      <v-divider></v-divider>
                      <v-card-text>
                        <v-data-table
                          v-model="selectedTransactions"
                          show-select
                          :headers="headersTransaction"
                          :items="transactionCollection"
                          class="elevation-1"
                          :items-per-page="-1"
                          :hide-default-footer="true"
                          disable-pagination
                        >
                        </v-data-table>
                      </v-card-text>
                      <v-divider></v-divider>
                      <v-card-actions>
                        <v-spacer />
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="dialogSelectTransactions = false"
                        >
                          Close
                        </v-btn>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="handleAddTranslationsToList"
                          :disabled="selectedTransactions.length == 0"
                        >
                          Add to list
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="handleCloseModalForm">
                Close
              </v-btn>
              <v-btn
                v-if="formModel.status != 15"
                color="blue darken-1"
                text
                @click="handleSaveModalForm"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
          <DeleteModalDialog
            :dialogDelete="dialogDeleteProject"
            @closeDelete="handleCloseDeleteProject"
            @deleteItemConfirm="handleDeleteConfirmProject"
          >
          </DeleteModalDialog>

          <DeleteModalDialog
            :dialogDelete="dialogDelete"
            @closeDelete="handleCloseDeleteItem"
            @deleteItemConfirm="handleDeleteItemConfirm"
          >
          </DeleteModalDialog>
          <v-overlay :value="loader">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
        </v-dialog>
      </v-col>
    </v-row>
    <v-row class="fill-height">
      <v-col>
        <v-sheet height="64">
          <v-toolbar flat color="white">
            <v-btn
              outlined
              class="mr-4"
              color="grey darken-2"
              @click="setToday"
            >
              Today
            </v-btn>
            <!-- <v-spacer></v-spacer> -->
            <v-spacer />
            <v-toolbar-title>{{ title }}</v-toolbar-title>
            <v-spacer />
            <v-btn fab text small color="grey darken-2" @click="prev">
              <v-icon small>mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn fab text small color="grey darken-2" @click="next">
              <v-icon small>mdi-chevron-right</v-icon>
            </v-btn>
          </v-toolbar>
          <v-row justify="center">
            <v-dialog
              v-model="projectTimesDialog"
              persistent
              scrollable
              width="960px"
            >
              <v-card v-if="selectedCalendarItem">
                <v-card-title>
                  Project hours - {{ selectedCalendarItem.pr_number }}
                  <v-spacer></v-spacer>
                  <v-btn
                    @click="handleCloseProjectTimesDialog"
                    style="float: right; cursor: pointer"
                    icon
                    color="#26223c"
                  >
                    <v-icon> mdi-close</v-icon>
                  </v-btn>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                  <!-- <Members
                    :projectTimes="projectTimes"
                    :projectTimesData="projectTimesData"
                    :members="computedMembers"
                    :userCollection="computedMemberCollection"
                    :inputData="memberInputData"
                    @handleAddMember="handleAddMember"
                  ></Members> -->

                  <div>
                    <v-row>
                      <v-col cols="6" sm="6" md="6">
                        <label for="datepicker-full-width"> Date </label>
                        <!-- :min="selectedCalendarItem.begin_date"
                          :max="selectedCalendarItem.deadline" -->
                        <b-form-datepicker
                          v-model="projectDate"
                          calendar-width="100%"
                          locale="en"
                          class="mb-2"
                          start-weekday="1"
                          style="max-width: 260px"
                          required
                          @context="loadProjectDataHoursToDate"
                        ></b-form-datepicker>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <b>
                          Project standard hours:
                          {{ selectedCalendarItem.standard_time / 3600 }}h</b
                        >
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <v-data-table
                          v-if="computedMembers && computedMembers.length > 0"
                          :headers="headersMemberHours"
                          :items="computedMembers"
                          :items-per-page="-1"
                          :hide-default-footer="true"
                          disable-pagination
                          class="elevation-1"
                        >
                          <template v-slot:item.time="props">
                            <v-edit-dialog :return-value.sync="props.item.time">
                              {{ props.item.time }}
                              <template v-slot:input>
                                <v-text-field
                                  v-model="props.item.time"
                                  :rules="[numberRules]"
                                  type="number"
                                  min="0"
                                  label="Edit"
                                  single-line
                                  :disabled="selectedCalendarItem.status == 15"
                                ></v-text-field>
                              </template>
                            </v-edit-dialog>
                          </template>
                          <template v-slot:item.bonus_time="{ item }">
                            <span v-if="item.bonus_time">
                              {{ item.bonus_time.toFixed(2) }}
                            </span>
                          </template>
                          <template v-slot:body.append>
                            <tr style="font-weight: bold" class="fixed">
                              <td>Total project hours:</td>
                              <td>
                                {{
                                  computedMembers
                                    .map((entity) => {
                                      return entity.spent_time;
                                    })
                                    .reduce((a, b) => a + b)
                                }}
                              </td>
                              <td>
                                {{
                                  computedMembers
                                    .map((entity) => {
                                      return entity.bonus_time;
                                    })
                                    .reduce((a, b) => a + b)
                                }}
                              </td>
                              <td></td>
                            </tr>
                          </template>
                        </v-data-table>
                        <!-- <pre
                          >{{ computedMembers }}
                        </pre> -->
                      </v-col>
                    </v-row>
                  </div>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="handleCloseProjectTimesDialog"
                  >
                    Close
                  </v-btn>
                  <v-btn
                    v-if="selectedCalendarItem.status != 15"
                    color="blue darken-1"
                    text
                    @click="handleSaveProjectTimes"
                  >
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
              <v-overlay :value="loader">
                <v-progress-circular
                  indeterminate
                  size="64"
                ></v-progress-circular>
              </v-overlay>
            </v-dialog>
          </v-row>
        </v-sheet>
        <v-sheet height="1000">
          <v-calendar
            ref="calendar"
            v-model="focus"
            color="primary"
            :events="events"
            :event-color="getEventColor"
            :weekdays="[1, 2, 3, 4, 5, 6, 0]"
            :now="today"
            :type="type"
            :loader="loadingTable"
            @click:event="handleClickItem"
            @click:date="handleClickItem"
            @change="updateRange"
          ></v-calendar>
        </v-sheet>
      </v-col>
      <v-overlay verlay :value="loader">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import ApiService from "@/core/services/api.service";

import DeleteModalDialog from "@/view/components/DeleteModalDialog";
import Members from "./Members";
import Swal from "sweetalert2";

import i18nService from "@/core/services/i18n.service.js";

const now = new Date();
let day = now.getDate() < 10 ? "0" + now.getDate() : now.getDate();
let month =
  now.getMonth() + 1 < 10 ? "0" + (now.getMonth() + 1) : now.getMonth() + 1;

const today = now.getFullYear() + "-" + month + "-" + day;

const initialFormData = () => ({
  id: null,
  status: 0,
  begin_date: today,
  deadline: null,
  site_id: null,
  user_id: null,
  comment: "",
  standard_time: null,
  entities: [],
  members: [],
});

const initialInputData = () => ({
  so: null,
  item: null,
  quantity: null,
  quantityUnit: null,
  num_of_pcs: null,
  quantityCollection: [],
});

const initialMemberInputData = () => ({
  user_id: null,
  type: 1,
  date: null,
});

export default {
  components: { DeleteModalDialog, Members },
  data: () => ({
    loader: false,
    today: today,
    focus: "",
    type: "month",
    typeToLabel: {
      month: "Month",
      week: "Week",
      day: "Day",
      "4day": "4 Days",
    },
    statuses: [0, 5, 10, 15],
    selectedLocale: i18nService.languages.find((item) => {
      return item.lang == i18nService.getActiveLanguage();
    }),
    headersMemberHours: [
      {
        text: "Project member",
        value: "fullName",
      },
      { text: "Project hours", value: "spent_time" },
      { text: "Bonus hours", value: "bonus_time" },
      { text: "Daily hours", value: "time" },
    ],
    // statusLine: false,
    headers: [
      {
        text: "Order",
        align: "start",
        sortable: false,
        value: "order",
      },
      {
        text: "TO/PO ID",
        align: "start",
        sortable: false,
        value: "transaction_number",
      },
      {
        text: "Destination",
        align: "start",
        value: "address",
      },
      {
        text: "Dedline",
        align: "start",
        value: "deadline",
      },
      {
        text: "Comment",
        align: "start",
        sortable: false,
        value: "comment",
      },
      {
        text: "Weight",
        align: "start",
        sortable: false,
        value: "weight",
      },
      { text: "Actions", value: "actions", sortable: false },
    ],

    headersEntities: [
      {
        text: "Entity ID",
        value: "item_entity_id",
      },
      {
        text: "Linked SO ID",
        value: "linked_so_id",
      },
      {
        text: "Serial number",
        value: "serial_number",
      },
      {
        text: "Reference",
        value: "reference",
      },
      {
        text: "Item name",
        value: "name",
      },
      {
        text: "Quantity",
        value: "quantity",
      },
      {
        text: "Quantity unit",
        value: "quantityUnit",
      },
      {
        text: "Standard time",
        value: "standard_time",
      },
      {
        text: "Status",
        value: "status",
      },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
      },
    ],
    headersComponents: [
      {
        text: "Entity ID",
        value: "item_entity_id",
      },
      // {
      //   text: "Project Tr. ID",
      //   value: "transaction_id",
      // },
      {
        text: "Parent entity ID",
        value: "parent_item_entity_id",
      },
      {
        text: "Serial number",
        value: "serial_number",
      },
      {
        text: "Reference",
        value: "reference",
      },
      {
        text: "name",
        value: "name",
      },
      {
        text: "Quantity",
        value: "quantity",
      },
      {
        text: "Quantity unit",
        value: "quantityUnit",
      },
      {
        text: "Status",
        value: "status",
      },
    ],

    headersTransaction: [
      {
        text: "Reference number",
        value: "reference_number",
      },
      {
        text: "Name",
        value: "name",
      },
      {
        text: "Quantity",
        value: "quantity",
      },
      {
        text: "Quantity unit",
        value: "quantityUnit",
      },
      {
        text: "Scheduled date",
        value: "scheduled_date",
      },
    ],

    requiredRules: [(v) => !!v || "Field is required"],
    numberRules: (v) => !isNaN(v) || "Field must be a number",

    permissions: JSON.parse(localStorage.permissions),
    permissionTo: "transport.itiner.",

    start: null,
    end: null,
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    formModel: initialFormData(),
    dialog: false,
    colors: [
      "blue",
      "indigo",
      "deep-purple",
      "cyan",
      "green",
      "orange",
      "grey darken-1",
    ],
    names: [
      "Meeting",
      "Holiday",
      "PTO",
      "Travel",
      "Event",
      "Birthday",
      "Conference",
      "Party",
    ],
    dialogDelete: false,
    dialogDeleteProject: false,
    editedItem: null,
    editedIndex: null,
    formValid: false,
    messages: Object.assign({}, initialFormData()),
    formInputDataValid: false,
    loadingTable: true,
    inputData: initialInputData(),
    addressesCollection: [],
    vehicleCollection: [],
    driverSelectCollection: [],
    filterOptions: 0,
    selectOptions: {
      project: { id: 0, name: "Select project" },
      employee: { id: 0, name: "Select employee" },
      pm: { id: 0, name: "Select PM" },
    },

    eventColors: [
      "#0466c8",
      "#0353a4",
      "#023e7d",
      "#002855",
      "#26223c",
      "#001233",
      "#33415c",
      "#fc677d",
      "#7d8597",
      "#979dac",
    ],

    defaultTab: 0,
    memberInputData: initialMemberInputData,

    dialogSelectTransactions: false,
    selectedTransactions: [],
    transactionCollection: [],
    projectTimesDialog: false,
    projectTimes: true,
    selectedCalendarItem: null,
    projectDate: null,
    projectDataMembers: [],
  }),
  computed: {
    ...mapGetters([
      "projectCollection",
      "siteCollection",
      "userCollection",
      "itemCollection",
      "salesOrderCollection",
    ]),

    formTitle() {
      return this.formModel.id
        ? "Project - " + this.formModel.pr_number
        : "New project";
    },

    title() {
      const { start, end } = this;
      if (!start || !end) {
        return "";
      }

      const startMonth = this.monthFormatter(start);
      const endMonth = this.monthFormatter(end);
      const suffixMonth = startMonth === endMonth ? "" : endMonth;

      const startYear = start.year;
      const endYear = end.year;
      const suffixYear = startYear === endYear ? "" : endYear;

      const startDay = start.day + this.nth(start.day);
      const endDay = end.day + this.nth(end.day);

      switch (this.type) {
        case "month":
          return `${startMonth} ${startYear}`;
        case "week":
        case "4day":
          return `${startMonth} ${startDay} ${startYear} - ${suffixMonth} ${endDay} ${suffixYear}`;
        case "day":
          return `${startMonth} ${startDay} ${startYear}`;
      }
      return "";
    },
    monthFormatter() {
      return this.$refs.calendar.getFormatter({
        timeZone: "UTC",
        month: "long",
      });
    },

    events() {
      this.projectCollection.map((item) => {
        item.name = item.pr_number;
        item.start = item.begin_date;
        item.end = item.deadline;
        item.color = this.eventColors[item.pr_number.at(-1)];
        item.timed = false;
        return item;
      });

      // return this.projectCollection;
      let filteredProjectCollection = this.projectCollection.filter((item) => {
        let filtered = false;
        if (item.status == this.filterOptions) {
          filtered = true;

          if (
            this.selectOptions.project.id > 0 &&
            this.selectOptions.project.id != item.id
          ) {
            filtered = false;
          }

          if (
            this.selectOptions.employee.id > 0 &&
            !item.members.find(
              (pm) => pm.user_id == this.selectOptions.employee.id
            )
          ) {
            filtered = false;
          }

          if (
            this.selectOptions.pm.id > 0 &&
            !item.members.find((pm) => pm.user_id == this.selectOptions.pm.id)
          ) {
            filtered = false;
          }
        }

        return filtered;
      });

      return filteredProjectCollection;
    },

    itemCollectionManufactured() {
      return this.itemCollection.filter((i) => i.manufacture == 1);
    },

    computedSalesOrderCollection() {
      return this.salesOrderCollection.filter(
        (i) => i.status == "Contracted" || i.status == "Preparation"
      );
    },

    computedMemberCollection() {
      let memberIds = this.formModel.members.map((i) => i.user_id);
      return this.userCollection.filter((user) => {
        return (
          !memberIds.includes(user.id) &&
          user.user_groups
            .map((ug) => ug.id)
            .filter((i) => i == process.env.VUE_APP_WORKERS_GROUP_ID).length > 0
        );
      });
    },

    computedPMMemberCollection() {
      // let memberIds = [];

      return this.userCollection.filter((user) => {
        // !memberIds.includes(user.id) &&
        return (
          user.user_groups
            .map((ug) => ug.id)
            .filter((i) => i == process.env.VUE_APP_PM_GROUP_ID).length > 0
        );
      });
    },

    computedMembers() {
      let members = this.formModel.members;

      // if (this.formModel.user_id) {
      //   members.push({ user_id: this.formModel.user_id, type: 2 });
      // }

      return members.map((i) => {
        i.fullName = this.userCollection.find(
          (u) => u.id == i.user_id
        ).fullName;
        i.spent_time = (1 * i.spent_time) / 3600;
        i.bonus_time = (1 * i.bonus_time) / 3600;
        if (i.spent_time < 0.01) i.spent_time = i.spent_time * 3600;

        return i;
      });
    },

    computedEntitiesCollection() {
      return this.formModel.entities.map((i) => {
        let item = this.$store.getters.getItemByID(i.item_id);
        let x = {};
        x.name = item ? item.name : " ";
        x.reference = i.reference_number;
        x.standard_time = i.standard_time / 3600 + " h";
        x.item_entity_id = i.item_entity_id;
        x.quantity = i.quantity;
        x.serial_number = i.serial_number;
        x.quantityUnit = this.$store.getters.getQuantityUnitByID(
          i.quantity_unit_id
        ).name;
        x.status = this.$t("ADJUSTMENT.status_" + i.status);
        if (!i.transactions) i.transactions = [];
        let transaction = i.transactions.find(
          (tr) =>
            tr.transaction_type == "Modules\\Manufacture\\Entities\\Project"
        );
        if (transaction) {
          x.transactionId = transaction.id;
        }

        let transactionSO = i.transactions.find(
          (tr) => tr.transaction_type == "Modules\\Sales\\Entities\\SalesOrder"
        );

        if (transactionSO) {
          x.linked_so_id = transactionSO.transaction_number;
        }

        return x;
      });
    },

    computedComponentsCollection() {
      if (!this.formModel.components) return [];
      return this.formModel.components.map((i) => {
        let item = this.$store.getters.getItemByID(i.item_id);

        let x = {};
        x.name = item.name;
        x.reference = i.reference_number;
        x.parent_item_entity_id = i.parent_item_entity_id;
        x.item_entity_id = i.item_entity_id;
        x.transaction_id = i.transaction_id;
        x.quantity = -1 * i.quantity;
        x.serial_number = i.serial_number;
        x.quantityUnit = this.$store.getters.getQuantityUnitByID(
          i.quantity_unit_id
        ).name;
        x.status = this.$t("ADJUSTMENT.status_" + i.status);
        return x;
      });
    },

    computedProjectCollection() {
      let projectCollection = [{ id: 0, name: "Select project" }];

      this.events.forEach((item) => {
        projectCollection.push({ id: item.id, name: item.name });
      });

      return projectCollection;
    },

    computedEmployeeCollection() {
      let employeeCollection = [{ id: 0, name: "Select employee" }];

      this.events.forEach((item) => {
        item.members
          .filter((member) => member.type == 1)
          .forEach((i) => {
            if (!employeeCollection.find((pm) => pm.id == i.user_id)) {
              employeeCollection.push({ id: i.user_id, name: i.name });
            }
          });
      });

      return employeeCollection;
    },

    computedPMCollection() {
      let PMCollection = [{ id: 0, name: "Select PM" }];

      this.events.forEach((item) => {
        item.members
          .filter((member) => member.type == 2)
          .forEach((i) => {
            if (!PMCollection.find((pm) => pm.id == i.user_id)) {
              PMCollection.push({ id: i.user_id, name: i.name });
            }
          });
      });

      return PMCollection;
    },
  },

  watch: {
    "$route.params.id": {
      handler: function (id) {
        if (id) {
          this.$nextTick(() => {
            this.editItem(id);
          });
        }
      },
      deep: true,
      immediate: true,
    },

    "inputData.deadline": {
      handler: function (deadline) {
        if (deadline) {
          this.inputData.deadline = deadline.slice(0, 5);
        }
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    ...mapActions([
      "fetchProject",
      "fetchSite",
      "fetchUser",
      "fetchItem",
      "fetchSalesOrder",
      "fetchQuantityUnit",
    ]),

    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = this.today;
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },

    updateRange({ start, end }) {
      const events = [];

      const min = new Date(`${start.date}T00:00:00`);
      const max = new Date(`${end.date}T23:59:59`);
      const days = (max.getTime() - min.getTime()) / 86400000;
      const eventCount = this.rnd(days, days + 20);

      for (let i = 0; i < eventCount; i++) {
        const allDay = this.rnd(0, 3) === 0;
        const firstTimestamp = this.rnd(min.getTime(), max.getTime());
        const first = new Date(firstTimestamp - (firstTimestamp % 900000));
        const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000;
        const second = new Date(first.getTime() + secondTimestamp);

        events.push({
          name: this.names[this.rnd(0, this.names.length - 1)],
          start: this.formatDate(first, !allDay),
          end: this.formatDate(second, !allDay),
          color: this.colors[this.rnd(0, this.colors.length - 1)],
        });
      }

      this.start = start;
      this.end = end;
      this.events = events;
    },
    nth(d) {
      return d > 3 && d < 21
        ? "th"
        : ["th", "st", "nd", "rd", "th", "th", "th", "th", "th", "th"][d % 10];
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    formatDate(a, withTime) {
      return withTime
        ? `${a.getFullYear()}-${
            a.getMonth() + 1
          }-${a.getDate()} ${a.getHours()}:${a.getMinutes()}`
        : `${a.getFullYear()}-${a.getMonth() + 1}-${a.getDate()}`;
    },

    loadFormData(id) {
      return new Promise((resolve) => {
        ApiService.get("manufacture/project/" + id)
          .then(({ data }) => {
            resolve(data);
          })
          .catch((error) => {
            console.log("Error!: ", error);
          });
      });
    },

    editItem(id = null) {
      if (id) {
        this.loader = true;
        this.loadFormData(id)
          .then((data) => {
            this.formModel = Object.assign({}, data);
            this.formModel.user_id = this.formModel.members.find(
              (member) => member.type == 2
            )
              ? this.formModel.members.find((member) => member.type == 2)
                  .user_id
              : null;
            // this.calculateDriverSelectCollection();
            setTimeout(() => {
              this.dialog = true;
            }, 10);
          })
          .finally(() => {
            this.loader = false;
          });
      }
    },

    handleAddItemToList() {
      this.$refs.formInputData.validate();
      if (this.formInputDataValid) {
        for (let i = 0; i < this.inputData.num_of_pcs; i++) {
          this.formModel.entities.push({
            item_id: this.inputData.item.id,
            reference_number: this.inputData.item.reference,
            quantity: this.inputData.quantity,
            quantity_unit_id: this.inputData.item.quantity_unit_id,
            transaction_id: null,
            standard_time: this.inputData.item.standard_time,
          });
        }

        this.inputData = Object.assign({}, initialInputData());
        this.$refs.formInputData.resetValidation();
      }
    },

    fetchModel() {
      this.selectedTransactions = [];
      this.transactionCollection = [];
      return new Promise((resolve) => {
        this.fetchProject();
        this.fetchUser();
        this.fetchSite();
        this.fetchItem();
        this.fetchQuantityUnit();
        this.fetchSalesOrder("sales/salesOrder/active");
        resolve();
      });
    },

    handleSaveModalForm() {
      if (this.formModel.entities.length == 0) {
        Swal.fire({
          title: "",
          text: "Entity list can not be empty!",
          icon: "error",
        });
      }

      this.$refs.form.validate();
      if (this.formValid && this.formModel.entities.length > 0) {
        this.loader = true;
        if (!this.formModel.id) {
          this.formModel.standard_time = this.formModel.entities
            .map((entity) => {
              return entity.standard_time;
            })
            .reduce((a, b) => a + b);
          ApiService.post("manufacture/project", this.formModel)
            .then(() => {
              this.handleCloseModalForm();
              this.$refs.form.resetValidation();
              this.fetchModel();
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data;
                if (errors) {
                  for (let field in errors) {
                    this.setError(field, errors[field][0]);
                    console.log(field, errors[field][0]);
                  }
                }
              } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log("Error", error.message);
              }
              console.log("Error!: ", error);
              // this.modalData.loading = false;
            })
            .finally(() => {
              this.loader = false;
            });
        } else {
          ApiService.put(
            "manufacture/project/" + this.formModel.id,
            this.formModel
          )
            .then(() => {
              this.handleCloseModalForm();
              this.$refs.form.resetValidation();
              this.fetchModel();
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data;
                if (errors) {
                  for (let field in errors) {
                    this.setError(field, errors[field][0]);
                  }
                }
              } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log("Error", error.message);
              }
              console.log("Error!: ", error);
              // this.modalData.loading = false;
            })
            .finally(() => {
              this.loader = false;
            });
        }
      }
    },

    loadProjectDataHoursToDate() {
      if (this.projectDate) {
        ApiService.get(
          `manufacture/project/${this.selectedCalendarItem.id}/${this.projectDate}/times`
        ).then(({ data }) => {
          this.formModel.members = data.members.map((member) => {
            let time = data.times.find(
              (time) => time.user_id == member.user_id
            );
            member.time = time ? (1 * time.time) / 3600 : 0;
            // member.time = time;

            return member;
          });

          // this.projectDataMembers = [...this.computedMembers];
        });
      }
    },

    handleSaveProjectTimes() {
      let times = [];
      // this.projectDataMembers.forEach((member) => {
      this.computedMembers.forEach((member) => {
        times.push({
          user_id: member.user_id,
          time: member.time * 3600,
          comment: null,
        });
      });
      this.loader = true;
      ApiService.post(
        `manufacture/project/${this.selectedCalendarItem.id}/${this.projectDate}/times`,
        { times: times }
      ).then(() => {
        this.fetchModel();
        this.loadProjectDataHoursToDate();
        this.loader = false;
        // this.handleCloseProjectTimesDialog();
      });
    },

    handleCloseProjectTimesDialog() {
      this.selectedCalendarItem = null;
      this.projectTimesDialog = false;
      this.projectDataMembers = [];
    },

    handleClickItem(item) {
      if (item.event && this.permissionCan("view")) {
        this.selectedCalendarItem = item.event;
        this.projectDate = this.selectedCalendarItem.begin_date;
        // this.loadProjectDataHoursToDate();
        if (this.projectTimes) {
          this.projectTimesDialog = true;
        } else {
          this.$router.push(`/manufacture/projects/${item.event.id}`);
        }
      } else {
        this.formModel.begin_date = item.date;
        setTimeout(() => (this.dialog = true), 10);
      }
    },

    handleCloseModalForm() {
      this.formModel = Object.assign({}, initialFormData());
      this.inputData = Object.assign({}, initialInputData());
      if (this.$refs.formInputData) this.$refs.formInputData.resetValidation();
      this.$refs.form.resetValidation();
      if (this.$route.name !== "projects")
        this.$router.push({ name: "projects" });
      this.resetErrorMessages();
      this.formModel = initialFormData();
      this.messages = Object.assign({}, initialFormData());
      this.inputData = initialInputData();
      this.selectedCalendarItem = null;
      this.fetchModel();
      console.log(this.formModel);
      this.dialog = false;
    },

    deleteItem(item) {
      // this.editedItem = Object.assign({}, item);
      this.editedIndex = this.computedEntitiesCollection.indexOf(item);
      this.dialogDelete = true;
    },

    handleDeleteItemConfirm() {
      let entities = [...this.formModel.entities];
      entities.splice(this.editedIndex, 1);
      this.formModel.entities = entities;
      this.handleCloseDeleteItem();
    },

    handleCloseDeleteItem() {
      this.editedIndex = null;
      this.dialogDelete = false;
    },

    handleDeleteProject() {
      this.dialogDeleteProject = true;
    },

    handleCloseDeleteProject() {
      this.dialogDeleteProject = false;
    },

    handleDeleteConfirmProject() {
      ApiService.delete("manufacture/project/" + this.formModel.id).then(() => {
        this.fetchModel();
        this.dialogDeleteProject = false;
        this.handleCloseModalForm();
      });

      this.dialogDeleteProject = false;
    },

    handleInputItemChange(item) {
      if (item) {
        this.inputData.quantityUnit = this.$store.getters.getQuantityUnitByID(
          item.quantity_unit_id
        ).name;

        if (item.default_quantities) {
          this.inputData.quantityCollection = item.default_quantities;
        }
      } else {
        this.inputData.quantityCollection = [];
        this.inputData.quantityUnit = null;
      }
    },

    handleSOChange(SO) {
      ApiService.get(`sales/salesOrder/${SO.id}/transactions`)
        .then(({ data }) => {
          this.transactionCollection = data.map((item) => {
            item.quantityUnit = this.$helpers.getTranslated(
              item.quantity_unit_translations
            ).name;
            item.quantity = -1 * item.quantity;
            item.name = this.$helpers.getTranslated(item.translations).name;
            item.transaction_id = item.id;
            return item;
          });
          this.dialogSelectTransactions = true;
        })
        .catch((error) => {
          console.log("Error!: ", error);
        });
    },

    handleAddTranslationsToList() {
      this.formModel.entities = [
        ...this.formModel.entities.concat(this.selectedTransactions),
      ];
      this.selectedTransactions = [];
      this.inputData = initialInputData();

      this.dialogSelectTransactions = false;
    },

    handleAddMember(member) {
      this.formModel.members.push(member);
      this.memberInputData = initialMemberInputData();
    },

    handleChangeUserId() {
      if (
        this.formModel.user_id &&
        !this.formModel.members.find((i) => i.user_id == this.formModel.user_id)
      ) {
        this.formModel.members.push({
          user_id: this.formModel.user_id,
          type: 2,
        });
      }
      this.formModel.user_id = null;
    },

    handleItemToCompleted(item) {
      ApiService.get(`manufacture/project/${item.transactionId}/completeEntity`)
        .then(() => {
          this.editItem(this.formModel.id);
        })
        .catch(() => {
          Swal.fire({
            title: "",
            text: "Complete was unsuccessful!",
            icon: "error",
          });
        });
    },

    handleInProgress() {
      ApiService.get(`manufacture/project/${this.formModel.id}/startAll`).then(
        () => {
          this.editItem(this.formModel.id);
        }
      );
    },

    handleCompleted() {
      ApiService.get(`manufacture/project/${this.formModel.id}/completeAll`)
        .then(() => {
          this.editItem(this.formModel.id);
        })
        .catch(() => {
          Swal.fire({
            title: "",
            text: "Complete was unsuccessful!",
            icon: "error",
          });
        });
    },

    handleClose() {
      ApiService.get(`manufacture/project/${this.formModel.id}/close`).then(
        () => {
          this.editItem(this.formModel.id);
        }
      );
    },

    handleChangeBeginDate() {
      this.messages["begin_date"] = "";
      // this.fetchTransactions();
    },

    handleChangeDeadline() {
      this.messages["deadline"] = "";
      // this.fetchTransactions();
    },

    resetErrorMessages() {
      let errorData = Object.assign({}, initialFormData());
      this.messages = errorData;
      // this.messages = {}
    },

    setError(prop, value) {
      this.messages[prop] = value;
    },

    permissionCan(action) {
      return this.permissions.find((x) => x.name == this.permissionTo + action);
    },
  },

  mounted() {
    this.fetchModel().then(() => (this.loadingTable = false));
    if (this.$refs.calendar) this.$refs.calendar.checkChange();
  },
};
</script>
