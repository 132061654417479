<template>
  <div>
    <v-row v-if="projectTimesData.status != 10">
      <v-col cols="3" sm="3" md="3" class="mt-2">
        <v-select
          v-model="inputData.user_id"
          :items="userCollection"
          label="Project members"
          item-text="fullName"
          item-value="id"
          :id="dynamicID"
          :rules="requiredRules"
          :disabled="projectTimesData.status == 15"
        />
      </v-col>
      <v-col cols="2" sm="2" md="2" v-if="projectTimesData.status != 15">
        <v-btn color="primary" dark class="mt-5" @click="handleAddItemToList">
          Add to list
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" md="12" v-if="projectTimesData">
        <b>
          Project standard hours:
          {{ projectTimesData.standard_time / 3600 }}h</b
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" md="12">
        <v-data-table
          v-if="members && members.length > 0"
          :headers="headers"
          :items="members"
          :items-per-page="-1"
          :hide-default-footer="true"
          disable-pagination
          class="elevation-1"
        >
          <template v-slot:item.bonus_time="{ item }">
            <span v-if="item.bonus_time">
              {{ item.bonus_time.toFixed(2) }}
            </span>
          </template>

          <template v-slot:item.spent_time="{ item }">
            <span v-if="item.spent_time">
              {{ +item.spent_time.toFixed(2) }}
            </span>
          </template>

          <template v-slot:body.append>
            <tr style="font-weight: bold" class="fixed">
              <td>Total project hours:</td>
              <td>
                {{
                  !isNaN(
                    members
                      .map((entity) => {
                        return 1 * entity.spent_time;
                      })
                      .reduce((a, b) => a + b)
                  )
                    ? members
                        .map((entity) => {
                          return 1 * entity.spent_time;
                        })
                        .reduce((a, b) => a + b)
                    : ""
                }}
              </td>
              <td>
                {{
                  !isNaN(
                    members
                      .map((entity) => {
                        return entity.bonus_time;
                      })
                      .reduce((a, b) => a + b)
                  )
                    ? members
                        .map((entity) => {
                          return entity.bonus_time;
                        })
                        .reduce((a, b) => a + b)
                    : ""
                }}
              </td>
              <td></td>
            </tr>
          </template>
        </v-data-table>
        <!-- <pre
          >{{ members }}
        </pre> -->
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "members",
  props: [
    "userCollection",
    "inputData",
    "members",
    "projectTimes",
    "projectTimesData",
  ],
  data() {
    return {
      headers: [
        {
          text: "Project member",
          value: "fullName",
        },
        { text: "Project hours", value: "spent_time" },
        { text: "Bonus hours", value: "bonus_time" },
        { text: "Actions", value: "actions" },
      ],
      date: null,
    };
  },

  methods: {
    handleAddItemToList() {
      this.$emit("handleAddMember", {
        user_id: this.inputData.user_id,
        type: 1,
      });
    },
  },
};
</script>
